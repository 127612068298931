<template>
  <nav>
    <div class="nav-bar">
      <img id="logo" :src="require(`@/assets/logo_greenish3.png`)" />
      <div class="navbar">
        <router-link to="/">Inicio</router-link>
        <router-link to="/about">Politicas</router-link>
      </div>
    </div>
  </nav>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

div#app {
  width: 100vw;
  height: 100vh;
}
html {
  overflow: visible;
}
body {
  margin: 0 !important;
  background-image: url("~@/assets/fondo.png");
  background-repeat: no-repeat;
  background-size: cover;
}

img#logo {
  width: initial;
  height: initial;
}
div.content {
  margin-top: 5px;
}
div.nav-bar {
  margin: 0 !important;
  display: flex;
  background-color: #ebfff7;
  -webkit-box-shadow: 0px 5px 4px 0px rgba(138, 138, 138, 1);
  -moz-box-shadow: 0px 5px 4px 0px rgba(138, 138, 138, 1);
  box-shadow: 0px 5px 4px 0px rgba(138, 138, 138, 1);
  height: 12%;
  width: 100vw;
  padding: 2px;
}

label.title {
  font-size: large;
  font-weight: 700;
}

.navbar {
  width: 100%;
  align-items: self-end;
  display: inline-flex;
  margin-left: 5%;
}

/* Navbar links */
.navbar a {
  float: left;
  text-align: center;
  padding: 12px;
  color: #aebfb8;
  text-decoration: none;
  font-size: 17px;
  margin: 12px;
}

/* Navbar links on mouse-over */
.navbar a:hover {
  background-color: #aebfb8;
  color: black;
}

/* Current/active navbar link */
.active {
  background-color: #4ab48a;
  color: black !important;
}

/* Add responsiveness - will automatically display the navbar vertically instead of horizontally on screens less than 500 pixels */
@media screen and (max-width: 390px) {
  .navbar a {
    float: none;
    display: block;
  }
}
</style>
