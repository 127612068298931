<template>
  <div class="content">
    <label class="title">{{ message }}</label>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  data() {
    return {
      message: "Some contenth here",
    };
  },
  components: {},
};
</script>
