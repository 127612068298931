import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDIHVHWhOMd1bU0H3yr53vJKGHtnkTygoU",
  authDomain: "petpals-companion.firebaseapp.com",
  projectId: "petpals-companion",
  storageBucket: "petpals-companion.appspot.com",
  messagingSenderId: "487656214264",
  appId: "1:487656214264:web:d5366a196ad8fbd350750d",
  measurementId: "G-2LYC97ZE5D",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

createApp(App).use(store).use(router).use(analytics).mount("#app");
